import React, { useState, useEffect } from "react";
import "./sessao-footer.scss";
import { Link } from "react-router-dom";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import {gestorReqs} from "../../../servicos/gestor";
import {useHeaderContext} from "../sessao-header/sessao-header-context";
import {FuncoesAuxiliares} from "../../../utilidades/funcoes-auxiliares";
import {ModalCadastroNewsletter} from "../../modal-cadastro-newsletter/modal-cadastro-newsletter";

type DadosInformacoes = {
    watermark: string;
    social_media: { link: string }[];
    address: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    phones: { phone: string }[];
    whatsapp: string;
    email: string;
    cnpj: string;
};

type DadosMenu = { name: string; link: string };
type MenuTree = { menu: { name?: string; html?: string; isLink: boolean } };

const SocialIcon = ({ href, title, iconClass }: { href: string; title: string; iconClass: string }) => (
    <a title={title} href={href} target="_blank" rel="noopener noreferrer">
        <i className={`bi ${iconClass}`} style={{ color: "white", fontSize: "1.6rem" }}></i>
    </a>
);

export const SessaoFooter = () => {
    const [dados, setDados] = useState<DadosInformacoes | null>(null);
    const [menus, setMenus] = useState<DadosMenu[]>([]);
    const [produtos, setProdutos] = useState<DadosMenu[]>([]);
    const { acessoRapidoData, setAcessoRapidoData } = useHeaderContext();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [informacoes, menusResponse, categoriasResponse] = await Promise.all([
                    gestorReqs.getInformacoes(),
                    gestorReqs.getMenus(),
                    gestorReqs.getCategorias()
                ]);

                setDados(informacoes as DadosInformacoes);

                const mtMenusFiltrado: MenuTree[] = menusResponse.dados!.filter(
                    (mt) => mt.menu.isLink && mt.menu.name !== "HOME"
                );
                const auxMenus: DadosMenu[] = mtMenusFiltrado.map((mt) => ({
                    name: mt.menu.name ?? "",
                    link: mt.menu.html ?? "",
                }));
                setMenus(auxMenus);

                const auxProdutos: DadosMenu[] = categoriasResponse.dados
                    ?.filter((c: any) => c?.category_id == null)
                    .map((categoria) => ({
                        name: categoria.title ?? "",
                        link: `categorias/${categoria.id}/${FuncoesAuxiliares.toUrlAmigavelConverter(categoria.title ?? "")}`
                    })) || [];
                setProdutos(auxProdutos);
            } catch (error) {
                console.error("Erro ao buscar dados: ", error);
            }
        };

        fetchData();
    }, []);

    if (!dados) {
        return <p>Carregando...</p>;
    }

    return (
        <section id="sessaoFooter">
            <ModalCadastroNewsletter />
            <Container>
                <Row className="desktop footerRow">
                    <Col lg={3} className="itemRodape">
                        <Link title="HOME" to="/" reloadDocument>
                            <div className="imgRodape">
                                <img src={dados.watermark} alt="logo" className="logo" width="100%" height="100%" />
                            </div>
                        </Link>
                        <div className="iconesRodape">
                            <SocialIcon href={dados.social_media[0]?.link ?? ""} title="Instagram" iconClass="bi-instagram" />
                            <SocialIcon href={dados.social_media[1]?.link ?? ""} title="Facebook" iconClass="bi-facebook" />
                            <SocialIcon href={dados.social_media[2]?.link ?? ""} title="Youtube" iconClass="bi-youtube" />
                        </div>
                    </Col>
                    <Col lg={2} className="itemRodape">
                        <h3 className="tituloRodape">Institucional</h3>
                        <div className="textoRodape">
                            {menus.map((menu, i) => (
                                <Link title={menu.name} to={menu.link} key={menu.name || i.toString()}>
                                    <li>
                                        <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> {menu.name}
                                    </li>
                                </Link>
                            ))}
                            <a
                                title="PORTAL DO REPRESENTANTE"
                                href="https://realmetais.accionsistemas.com.br/pSeven/login.seam?cid=1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <li>
                                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> Portal
                                    do Representante
                                </li>
                            </a>
                        </div>
                    </Col>
                    <Col lg={2} className="itemRodape">
                        <h3 className="tituloRodape">Produtos</h3>
                        <div className="textoRodape">
                            {produtos.map((menu, i) => (
                                <Link title={menu.name} to={menu.link} key={menu.name || i.toString()} reloadDocument>
                                    <li>
                                        <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> {menu.name}
                                    </li>
                                </Link>
                            ))}
                        </div>
                    </Col>
                    <Col lg={5} className="itemRodape">
                        <h3 className="tituloRodape">Contato</h3>
                        <div className="textoRodape">
                            <li>
                                <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> MH TORRES LTDA - CNPJ: {dados.cnpj}
                            </li>
                            <li>
                                <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> {dados.address}, {dados.number} -{" "}
                                {dados.neighborhood} - {dados.city} - {dados.state} - {dados.cep}
                            </li>
                            <a title={dados.phones[1]?.phone} href={"tel:" + dados.phones[1]?.phone}>
                                <li>
                                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> Telefone:{" "}
                                    {dados.phones[1]?.phone}
                                </li>
                            </a>
                            <a
                                title="WhatsApp"
                                href={`https://api.whatsapp.com/send/?phone=${dados.whatsapp.replace(/\D+/g, '')}&text&type=phone_number&app_absent=0`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <li>
                                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> Whatsapp: {dados.whatsapp}
                                </li>
                            </a>
                            <a title={dados.phones[0]?.phone} href={"tel:" + dados.phones[0]?.phone}>
                                <li>
                                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> Recepção:{" "}
                                    {dados.phones[0]?.phone}
                                </li>
                            </a>
                            <a className="email" title={dados.email} href={"mailto:" + dados.email}>
                                <li>
                                    <i className="bi bi-dash" style={{ color: "#00A859", fontSize: "0.7rem" }}></i> E-mail: {dados.email}
                                </li>
                            </a>
                        </div>
                    </Col>
                </Row>
                {/* Renderização para telas responsivas */}
                <Row className="responsivo">
                    <Col lg={12} className="itemRodape">
                        <div className="imgRodape">
                            <img src={dados.watermark} alt="logo" className="logo" width="100%" height="100%" />
                        </div>
                        <div className="iconesRodape">
                            <SocialIcon href="/" title="Instagram" iconClass="bi-instagram" />
                            <SocialIcon href="/" title="Facebook" iconClass="bi-facebook" />
                            <SocialIcon href="/" title="Youtube" iconClass="bi-youtube" />
                        </div>
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Institucional</Accordion.Header>
                                <Accordion.Body>
                                    {/* Institucional conteúdo responsivo */}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
